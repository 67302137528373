





























































import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import OfferCard from '@/components/OfferCard.vue'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    OfferCard,
    PageTitle,
    EmptyData,
    ConfigField
  }
})
export default class OffersList extends Mixins(ClipboardCopyMixin) {

  get activeBoard() {
    return this.$store.state.boardsState.activeBoard
  }

  get botLink() {
    const activeBoard = this.$store.state.boardsState.activeBoard
    
    if (activeBoard) {
      return `${ tgLoginLink(activeBoard.title) }?start=ads`
    }
  }

  toAdItem() {
    this.$router.push({
      name: "OffersListItem",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  toEdit(index:number) {
    this.$router.push({
      name: "OffersListItem",
      params: {
        adId: index.toString(),
        actionType: BaseItemViewAction.Edit
      }
    })
  }

  removeAdWarn(index: number) {
    this.$confirm({
      title: this.$t('delete_ad_warn_title').toString(),
      content: this.$t('delete_ad_warn_text').toString(),
      okText: this.$t('confirm').toString(),
      cancelText: this.$t('cancel').toString(),
      onOk: () => this.removeAd(index)
    })
  }

  removeAd(index: number) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.boardsState.activeBoard!.config.ads.splice(index, 1)
      },
    })

    this.$store.dispatch('savePostBoardConfig')
  }

  created() {
    if (!this.$store.state.shopState.products) {
      this.$store.dispatch('getProducts', { board_key: this.$store.state.boardsState.activeBoard.board })
    }
  }
}
