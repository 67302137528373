




































import { Advert } from '../includes/logic/Ads/types'
import AvatarsRow from '@/components/AvatarsRow.vue'

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({
  components: { AvatarsRow }
})
export default class OfferCard extends Vue {
  @Prop() advert!:Advert

  @Prop() weekDayIndex!:number

  @Emit()
  onRemoveClick() {}

  @Emit()
  onEditClick() {}

  get ruleText() {
    const { rules } = this.advert

    if (rules.length > 150) {
      return `${ rules.slice(0, 147) } ...`
    }

    return rules
  }

  get price() {
    const products = this.$store.state.shopState.products

    if (products) {
      const product = products.find(p => p.id === this.advert.product_id)

      if (product) {
        return `${ product.cost } ${ product.currency }`
      }

      return ''
    }

    return ''
  }

  get advertTargets() {
    return this.$store.getters.boardTargetOptions(this.$route.params[ EntityTypes.BOT_ID ])
      .filter(o => this.advert.targets.some(t => t === o.value))
  }
}
